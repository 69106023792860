svg {
    .primary {
        fill: theme('colors.blue');
    }
    .secondary {
        fill: theme('colors.grey');
    }
}

svg.solid {
    .primary {
        fill: theme('colors.blue');
    }
    .secondary {
        fill: theme('colors.blue');
    }
}

.sm-icon {
    max-width: 26px;
}

.md-icon {
    max-width: 35px;
}

.icon-watching {
    .primary {
        fill: theme('colors.coal');
    }
    .secondary {
        fill: theme('colors.red');
    }
}

.icon-disabled {
    .primary {
        fill: theme('colors.coal');
    }
    .secondary {
        fill: theme('colors.white');
    }
}