// Highlight Colors
//
// Colors we use for higlighting states.
//
// theme('colors.black') - #333333; black
// $red - #ef4b53; red
// $blue - #3791ff; blue
// $grey - #eaeaea; grey
// $white - #fff; white
//
// Styleguide Global.Colors



.bg-gradient {
    background-image: radial-gradient(circle, #3791ff, #2871c6, #1c5291);
    h1 {
        text-shadow: 2px 2px #1f5496;
    }
}