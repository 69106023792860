.subjects {
    &-list {
        max-height: 1500px;
        overflow: hidden;
        position: relative;
        transition: max-height 0.3s ease-in-out;
        padding-bottom: 30px;
        &--closed {
            max-height: 150px;
        }
    }
    &:after {
        content: '';
        width: 100%;
        position: absolute;
        height: 50px;
        background-image: linear-gradient(rgba(#fafafa,0), rgba(#fafafa,1));
        //background: red;
        bottom: 0;
        pointer-events: none;
    }
    svg {
        .secondary {
            fill: theme('colors.coal');
        }
    }
}