.campaign-card {
    @apply no-underline w-full relative block h-full rounded-lg;
    &__party {
        @apply  w-full px-4 h-[2px] text-white bg-blue absolute z-[1];
        &.party-I {
            @apply bg-purple;
        }
        &.party-R {
            @apply bg-red;
        }
        &.party-D {
            @apply bg-democrat_blue;
        }

    }
    &__content {
        @apply bg-white shadow-sm relative;
        transition:all 0.3s ease-in-out;
        &:hover {
            @apply
                shadow-2xl;
            transform: scale(101%);
        }
    }
    &--md {
        //@apply py-2;

        @screen md {
            //@apply pl-4;
        }
        .campaign-card__content {
            @apply flex;
        }
        .campaign-card__ribbon-holder {
            top: -2px;
        }
    }
    &__ribbon-holder {
        @apply absolute overflow-hidden;
        top: 0;
        right: 0;
        width: 99px;
        height: 150px;
    }
    &__ribbon {
        width: 150px;
        height: 22px;
        margin-right: -50px;
        margin-top: 15px;
        -ms-transform: rotate(45deg); /* IE 9 */
        -webkit-transform: rotate(45deg); /* Safari prior 9.0 */
        transform: rotate(45deg); /* Standard syntax */
        position: relative;
        z-index: 1;
        @apply text-sm;
        @apply
            uppercase
            text-center
            text-ghost
            text-sm
            bg-dystopia;
      }
      &__image {
        .unsplash-attribute {
            transition: bottom 0.3s ease-out;
            bottom: -25px;
        }
        &:hover {
            .unsplash-attribute {
                bottom: 0;
            }
        }
    }
    a {
        @apply no-underline;
    }
}

.global-card {
    @apply bg-white shadow-md relative border-grey border border-solid p-4;
    @screen md {
        @apply p-6;
    }
    @screen lg {
        @apply p-8;
    }
    &__aside {
        @screen md {
            top: 80px;
            position: sticky;
        }
    }
    &__headline {
        @apply flex text-center items-center justify-center;
        svg {
            @apply mr-2 -mt-2;
            .secondary {
                fill: theme('colors.red');
            }
        }
    }
    &__image {
        @apply absolute object-cover object-center w-full h-full top-0;
        transition: opacity 5s ease-out;
    }
}

.top-highlight {
    //border-top: solid 5px theme('colors.red');
    @apply bg-white p-8;
    @screen md {
        @apply w-1/3;
        top: 0px;
        position: sticky;
    }
}

.unsplash-attribute {
    @apply bottom-0 absolute w-full text-left px-4 text-xs py-1 block text-global no-underline lowercase;
    background-color: rgba(255,255,255,0.85);
    a {
        @apply underline text-global ;
    }
}