/*
Radial checkboxes

Radial checkbox inputs used for single selection.

Markup:
<form>
  <div class="form__radio">
    <input type="radio" id="radio-1" name="radios" />
    <label for="radio-1">
      <span></span>
      Choose me!
    </label>
  </div>
  <div class="form__radio">
    <input type="radio" id="radio-2" checked name="radios" />
    <label for="radio-2">
      <span></span>
      No, choose ME!
    </label>
  </div>
  <div class="form__radio">
    <input type="radio" id="radio-3" name="radios" disabled />
    <label for="radio-3">
      <span></span>
      Do not choose me.
    </label>
  </div>
</form>

Styleguide Elements.Form.Checkbox A
*/

.form {
  &__radio {
    @apply inline-block min-w-[100px];
    input[type=radio] {
      position: fixed;
      left: -9999px;
    }
    input[type=radio] + label {
      position: relative;
	    height: 28px;
      color: #373241;
      letter-spacing: 1px;
      line-height: 28px;
      margin: 8px 0;
      @apply block;
      &:enabled:hover {
          span {
            border-color: theme('colors.blue');
            @apply bg-blue;
          }
      }
      span {
          display: inline-block;
          width: 20px;
          height: 20px;
          top: 4px;
          margin-right: 5px;
          position: relative;
          @apply bg-black rounded-full;
          content: '';
          border: 1px solid theme('colors.blue');
          box-shadow: inset 0 0 0 10px theme('colors.white');
          transition: all 0.3s ease-in-out;
      }
    }
    input[type=radio]:checked + label span {
      border: 1px solid theme('colors.black');
      box-shadow: inset 0 0 0 2px theme('colors.white');
    }
    input[type=radio]:disabled + label {
      pointer-events: none;
      @apply text-black;
      span {
        border-color: theme('colors.grey');
        @apply bg-transparent opacity-50;
        box-shadow: none;
      }
    }
  }
}
