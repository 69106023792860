/*
Dropdown Select

Radial checkbox inputs used for single selection.

Markup:
<form>
  <div class="form__select">
    <select name="select-1" class="js-select2">
      <option>Option 1</option>
      <option>Option 2</option>
      <option>Option 3</option>
      <option>Option 4</option>
      <option>Option 5</option>
      <option>Option 6</option>
    </select>
  </div>
</form>
<script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"></script>
<link href="https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.6-rc.0/css/select2.min.css" rel="stylesheet" />
<script src="https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.6-rc.0/js/select2.min.js"></script>
<script>
$(document).ready(function() {
    $('.js-select2').select2({
      minimumResultsForSearch: -1
    });
});
</script>

Styleguide Elements.Form.Select
*/

.form__select {
  select {
    width: 100%;
  }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    @apply px-4 py-2 w-full text-sm;
    padding-top: .65rem;
  }

  .select2-container--default .select2-selection--single {
    border-color: theme('colors.grey');
    border-width: 1px;
    border-style: solid;
    border-radius: 0;
    &:focus {
      outline: none;
    }
  }

  .select2-container .select2-selection--single {
    height: 42px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 7px;
    right: 12px;
  }
  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: theme('colors.coal') transparent transparent  transparent;
    border-width: 6px 5px 0 5px;
    transform: rotate(180deg);
  }
  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: theme('colors.coal') transparent transparent  transparent;
    border-width: 6px 5px 0 5px;
    transition: transform ease-in-out .3s;
    transform-origin: center center;
  }
}

.select2-container--default {
  .select2-results {
    .select2-results__option {
      @apply px-4 py-2 w-full text-sm;
    }
    .select2-results__option--highlighted[aria-selected] {
      @apply bg-red;
    }
  }
}

.form-select {
  select {
    @apply w-full bg-white;
    border-radius: 0;
    font-family: NeuzitGrotesk, Helvetica Neue, sans-serif;
    line-height: 1;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    width: 100%;
    padding-top: .6rem;
    padding-bottom: .7rem;
    border-color: #d1d1d1;
    border-width: 1px;
    border-style: solid;
    display: block;
    font-size: 18px;
    position: relative;
    -webkit-transition: border .3s ease-in-out;
    transition: border .3s ease-in-out;
  }
}
