.faq__chevron {
    width: 15px;
    @apply h-auto;
}

.faq__qa {
    @apply border-shadow border-t;
    &:last-of-type {
        @apply border-b;
    }
    .faq__chevron {
        transform: rotate(180deg);
        transition: transform .3s ease-out;
    }
    .js-faq-answer {
        @apply overflow-hidden;
        max-height: 0px;
        transition: max-height .3s ease-out;
    }
    &--open {
        .js-faq-answer {
            max-height: 600px;
        }
        .faq__chevron {
            transform: rotate(0deg);
        }
    }
}


.block__accordion {
    &__section {
        @apply block py-12 relative border-b-2 border-white border-solid pr-24;
        background-position: center right;
        background-size: cover;
        & > * {
            pointer-events: none;
        }
        @screen md {
            @apply border-b;
            background-position: center center;
            background-size: cover;
        }
        @screen xl {
            @apply py-16;
        }
        &__toggle {
            @apply uppercase absolute text-white;
            top: calc(50% - 13px);
            right: 40px;
            &:after {
                content: '';
                @screen md {
                    content: 'COLLAPSE';
                }
                min-width: 30px;
                height: 25px;
                @apply inline-block tracking-widest;
                padding-right: 25px;
                background-image: url('/app/themes/rockmount/dist/images/chevron-up.svg');
                background-repeat: no-repeat;
                background-position: right;
                background-size: 18px 11px;
            }
        }
    }
    &--closed {
        .block__accordion__content {
            max-height: 0;
        }
        .block__accordion__section__toggle {
            &:after {
                content: '';
                @screen md {
                    content: 'EXPAND';
                }
                background-image: url('/app/themes/rockmount/dist/images/chevron.svg');
            }
        }
    }
    &__content {
        @apply overflow-hidden;
        max-height: 15000px;
        transition: max-height .3s ease-in-out;
    }
}