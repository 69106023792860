@import url("https://fonts.googleapis.com/css2?family=Baloo+2&display=swap");
:root {
  --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  --msger-bg: #fff;
  --left-msg-bg: linear-gradient(to right, #f89595, #f44141);
  --right-msg-bg: #ececec;
}
.msger {
  display: none;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 386px;
  margin: 25px 10px;
  border-radius: 10px;
  height: calc(80% - 100px);
  background: var(--msger-bg);
  //position: absolute;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9;
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
  &--active {
    display: flex;
  }
}
.msger-header {
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: 10px 10px 0px 0px;
  background: #eee;
  color: #666;
}
.msger-header-title{
  display: flex;
  align-items: center;
}
.msger-header-title img{
  height: 2.5rem;
  width: auto;
}
.msger-header-title p{
  letter-spacing: 1px;
  font-size: 1.8rem;
  font-weight: bold;
}
.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}
.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 10px;
}
.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.msg:last-of-type {
  margin: 0;
}
.msg-img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
.msg-bubble {
  max-width: 350px;
  padding: 15px;
  border-radius: 15px;
  background: var(--left-msg-bg);
}
.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
.msg-info-time {
  font-size: 0.85em;
}
.left-msg .msg-bubble {
  color: #fff;
  border-bottom-left-radius: 0;
}
.right-msg {
  flex-direction: row-reverse;
}
.right-msg .msg-bubble {
  background: var(--right-msg-bg);
  color: #000;
  border-bottom-right-radius: 0;
}
.right-msg .msg-img {
  margin: 0 0 0 10px;
}
.msger-inputarea {
  display: flex;
  padding: 10px;
  background: #f44141;
  border-radius: 0px 0px 10px 10px;
}
.msger-inputarea * {
  padding: 5px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}
.msger-input {
  flex: 1;
  background: transparent;
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.7);
  color: white;
  &::placeholder {
    color: #eee!important;
  }
}
.msger-send-btn {
  margin-left: 10px;
  background: transparent;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
}
.msger-chat {
  background-color: #fcfcfe;
}



.help-widget {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    fill: red;
    //fill: #fff;
    //color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 8;
    user-select: none;
    cursor: pointer;
  }
  .help-widget .svg-container {
    transform: translateZ(0);
  }
  .help-widget .svg {
    width: 50px;
    height: 50px;
  }
  .help-widget #icon-help-a, .help-widget #icon-help-b, .help-widget #icon-help-c {
    -webkit-transition: 0.02s fill ease;
    -moz-transition: 0.02s fill ease;
    -ms-transition: 0.02s fill ease;
    transition: 0.02s fill ease;
  }
  .help-widget #icon-help-a {
    fill: #999;
  }
  .help-widget #icon-help-c {
    fill: #333;
  }
  .help-widget:hover #icon-help-a {
    fill: #888;
  }
  .help-widget:hover #icon-help-c {
    fill: black;
  }

  .typing {
    position: relative;
  }

  .typing__dot {
    float: left;
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background: #fff;
    border-radius: 50%;
    opacity: 0;
    animation: loadingFade 1s infinite;
  }

  .typing__dot:nth-child(1) {
    animation-delay: 0s;
  }

  .typing__dot:nth-child(2) {
    animation-delay: 0.2s;
  }

  .typing__dot:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes loadingFade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
    }
  }
