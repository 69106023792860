
.table--backoffice {
  @apply w-full text-sm text-left;
  thead {
    th {
      @apply text-white p-2  font-semibold bg-dystopia;
      @screen md {
        @apply px-4 py-2;
      }
      strong { // placeholder for with icon
        @apply inline-flex items-center;
      }
    }
  }
  td {

  }
  @screen md {
    td > p {
      @apply p-1;
      @screen md {
        @apply p-4 ;
      }
    }
    td:nth-child(1) {
      @apply p-1;
      @screen md {
        @apply p-4 ;
      }
    }
    td:nth-child(2) {
      li {
        @apply p-1 m-0;
        @screen md {
          @apply p-4 ;
        }
      }
      li:nth-child(odd) {
        @apply bg-[#f1f1f1];
      }
      li:nth-child(even) {
        @apply bg-[#eaebec];
      }
    }
    tr:nth-child(odd) td {

    }
  }
}
