.more-link {
    @apply w-full block mb-12 no-underline text-sm whitespace-nowrap;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-align: center;
    svg {
        @apply inline-block ml-3;
    }
    &:before, &:after {
        position: absolute;
        top: 51%;
        overflow: hidden;
        width: calc(50%);
        height: 1px;
        content: '\a0';
        background-color: #1F3653;
        opacity: 0.25;
    }
    &:before {
        margin-left: -50%;
        text-align: right;
    }
    &.hidden {
        display: none;
    }
}
