.navbar {
    @apply bg-white sticky top-0 z-10;
    box-shadow: 0 1px 2px rgba(0,0,0,0.3);
    @screen md {
      @apply static top-auto z-auto;
    }
    .container {
        @apply
            m-auto
            items-center
            flex;
    }
    .nav-link {
        @apply
            text-white
            py-1
            no-underline;
      @screen md {
        @apply text-black;
      }

      &--active, &:hover {
        @apply border-b-2 border-blue text-blue;
      }
    }

    &-nav {
        @screen md {
          @apply -mx-4;
        }
        li {
            @apply
                self-center
                px-4;
        }
    }
}

.main {
  transition: transform .3s ease-in-out;
}

.nav-open {
  @apply overflow-hidden;
  //@apply bg-red;
  .main {
    transform: translateX(-100%);
  }
  .mobile-nav {
    //transform: translateX(0%);
    left: 0;
    @apply overflow-auto;
  }
}

.navbar-nav {
  .nav-item {
    @apply w-full text-center;
  }
  @apply hidden;
  @screen md {
    @apply justify-end flex-grow hidden my-4 relative bg-transparent inset-auto text-black text-base;
    transform: none;
    transition: none;
    @apply flex;
    .nav-item {
      @apply w-auto;
    }
  }
}

.mobile-nav {
  @apply grid grid-cols-1 content-center fixed inset-0 bg-blue h-full w-full text-white text-2xl;
  transition: left .3s ease-in-out;
  //transform: translateX(100%);
  left: 100%;
  @screen md {
    @apply hidden;
  }
  a {
    @apply text-white no-underline text-center w-full block p-3 text-2xl;
  }
}
.js-nav-account-holder {

}

.nav-account-holder {

  .nav-account-holder__dropdown {
    @apply opacity-0 pointer-events-none top-0;
    transition: top 0.3s ease-in-out, opacity 0.3s ease-in-out;;
  }
  &--open {
    svg {
      transform: rotate(180deg);
    }
    .nav-account-holder__dropdown {
      @apply pointer-events-auto opacity-100 top-[33px];
    }
  }
}