:root {
  --color-light: #eaeaea;
  --color-dark: theme('colors.black');
}

html, body {
  @apply text-base text-global font-serif leading-normal font-thin;
}

.text-headline {
  @apply text-2xl leading-none font-thin;
  @screen md {
    @apply text-3xl;
  }
  @screen lg {
    @apply text-4xl;
  }
}

.headline--dashed {
  @apply flex items-center text-lg font-semibold;
  &::before, &::after {
    content: '';
    @apply block border-b border-highlight flex-grow;
  }
  &::before {
    @apply mr-6;
  }
  &::after {
    @apply ml-6;
  }
}

.text-subheadline {
  @apply text-base font-thin leading-relaxed;
}

.redacted {
  @apply text-brand;
}

.big-ol {
  @apply py-8;
  counter-reset: li;
  li {
    @apply relative pl-12;
    counter-increment: li;
    strong {
      @apply font-bold text-lg;
    }
  }
  li:before {
    @apply bg-dystopia text-white flex justify-center items-center w-8 h-8 rounded-full absolute left-0 top-1;
    content: counter(li);
  }
}