/*
Checkbox inputs

Checkbox inputs used for multiple selections.

Markup:
<form>
  <div class="form__checkbox form__checkbox--alt">
    <input type="checkbox" id="checkbox-1" name="checkbox" />
    <label for="checkbox-1">
      <span></span>
      Choose me!
    </label>
  </div>
  <div class="form__checkbox form__checkbox--alt">
    <input type="checkbox" id="checkbox-2" checked name="checkbox" />
    <label for="checkbox-2">
      <span></span>
      No, choose ME!
    </label>
  </div>
  <div class="form__checkbox form__checkbox--alt">
    <input type="checkbox" id="checkbox-3" name="checkbox" disabled />
    <label for="checkbox-3">
      <span></span>
      Do not choose me.
    </label>
  </div>
</form>

Styleguide Elements.Form.Checkbox Standard
*/

.form {
  &__checkbox {
    input[type=checkbox] {
      position: fixed;
      left: -9999px;
    }
    input[type=checkbox] + label {
      position: relative;
      color: #373241;
      @apply text-sm;
      letter-spacing: 1px;
      line-height: 28px;
      @apply block;
      &:enabled:hover {
          span {
            border-color: theme('colors.blue');
            @apply bg-blue;
          }
      }
      span {
          display: inline-block;
          width: 20px;
          height: 20px;
          top: 4px;
          margin-right: 5px;
          position: relative;
          @apply bg-black rounded-sm;
          content: '';
          border: 1px solid theme('colors.black');
          box-shadow: inset 0 0 0 10px theme('colors.white');
          transition: all 0.3s ease-in-out;
      }
    }
    input[type=checkbox]:checked + label span {
      box-shadow: inset 0 0 0 2px theme('colors.white');
    }
    input[type=checkbox]:disabled + label {
      pointer-events: none;
      @apply text-black;
      span {
        border-color: theme('colors.coal');
        @apply bg-transparent opacity-50;
        box-shadow: none;
      }
    }
  }
}

/*
Checkbox inputs alternate

Alternate no-image checkbox inputs used for multiple selections.

Markup:
<form>
  <div class="form__checkbox">
    <input type="checkbox" id="alt-checkbox-1" name="checkbox-alt" />
    <label for="alt-checkbox-1">
      <span></span>
      Choose me!
    </label>
  </div>
  <div class="form__checkbox">
    <input type="checkbox" id="alt-checkbox-2" checked name="checkbox-alt" />
    <label for="alt-checkbox-2">
      <span></span>
      No, choose ME!
    </label>
  </div>
  <div class="form__checkbox">
    <input type="checkbox" id="alt-checkbox-3" name="checkbox-alt" disabled />
    <label for="alt-checkbox-3">
      <span></span>
      Do not choose me.
    </label>
  </div>
</form>

Styleguide Elements.Form.Checkbox Z
*/

.form__checkbox--alt {
  input[type=checkbox] + label {
    &:hover span {
      @apply bg-white;
    }
    span {
      @apply bg-white;
      background-image:url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjE3LjZweCIgaGVpZ2h0PSIxNXB4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjx0aXRsZT5TdWNjZXNzPC90aXRsZT4NCjxnPjxwYXRoIGZpbGw9IiM0NDM4M0YiIGNsYXNzPSJzdDAiIGQ9Ik02LjEsMTFsOS4yLTExbDIuMiwxLjhMNi41LDE1TDAsOS42bDEuOC0yLjJMNi4xLDExeiIvPjwvZz4NCjwvc3ZnPg0K');
      background-size: 13px 11px;
      background-repeat: no-repeat;
      background-position: 2px 3px;
    }
  }
  input[type=checkbox]:checked + label span {
    box-shadow: inset 0 0 0 0px theme('colors.white');
  }
}
