// Cloud vars
$clouds:
  ("/images/heros/clouds_2.png" 1000px 20s),
  ("/images/heros/clouds_1.png" 1000px 15s),
  ("/images/heros/clouds_3.png" 1579px 17s);

// Animation & keyframes
// Refactor by @HugoGiraudel
// https://codepen.io/HugoGiraudel
@for $i from 1 through length($clouds) {

  $item     : nth($clouds, $i);
  $path     : nth($item, 1);
  $width    : nth($item, 2);
  $duration : nth($item, 3);

  @keyframes clouds-loop-#{$i} {
    to { background-position: -$width 0; }
  }

  .clouds-#{$i} {
    animation: clouds-loop-#{$i} $duration+50 infinite linear;
    opacity: 0;
    transition: opacity 3s ease-out;
    .clouds--loaded & {
      background-image: url($path);
      opacity: 1;
    }
  }
}


.clouds {
  opacity: 0.2;
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  top:      0;
  left:     0;
  right:    0;
  height:   100%;
}

.clouds-1,
.clouds-2,
.clouds-3 {
  background-repeat: repeat-x;
  position: absolute;
  top:      0;
  right:    0;
  left:     0;
  height:   500px;
}