
.button {
  @apply
    bg-blue
    inline-block
    text-white
    font-normal
    text-base
    text-center
    no-underline
    py-2 px-5
    rounded-sm;
  min-height: 40px;
  appearance: none;
  letter-spacing: .4px;
  &:hover, &:focus {
    @apply bg-democrat_blue transition-colors;
  }
  svg {
    //opacity: 0;
    @apply relative ml-1;
    left: 5px;
    transition: all .3s ease-in-out;
  }
  &:visited, &:active {
    @apply text-white;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &:hover:not(.nav),
  &:focus:not(.nav) {
      //animation: shadow-pulse 1s infinite;
      //animation-direction: alternate;
      svg {
        left: 10px;
        opacity: 1;
      }
      outline: none;
      opacity: 1;
  }
}

.button {
  @apply inline-flex items-center w-auto text-center justify-center;
  &--sm {
    padding: 5px 12px;
    @apply text-xs;
  }
  &--md {
    padding: 15px 42px;
    @apply text-base;
  }
  &--lg {
    padding: 30px 52px;
  }
  &--full {
    width: 100%;
    @apply flex;
  }
  &--alt {
  	border: 2px solid theme('colors.coal');
    @apply bg-white text-black;
    box-shadow: inset 0 0 0 2px theme('colors.white'), 0 2px 10px 0 rgba(0,0,0,0);
    &:hover,
    &:focus {
      @apply bg-white text-black;
      //animation: shadow-pulse-alt 1s infinite;
      //animation-direction: alternate;
    }
    &:visited, &:active {
      @apply text-black;
    }
  }
  &--link {
    @apply text-sm;
    @screen md {
      @apply text-base;
    }
  }
  &--plain {
    @apply text-red;
    transition: color .2s ease-in-out;
    border: none;
    box-shadow: none;
    background: transparent;
    min-width: auto;
    padding: 0;
    text-decoration: underline;
    text-underline-position: under;
    &:visited,
    &:active {
      @apply text-red;
    }
    &:hover,
    &:focus {
        @apply text-black;
        box-shadow: none;
        background: transparent;
        animation: none;
    }

  }
  [disabled], &--disabled {
    @apply bg-black;
    pointer-events: none;
    box-shadow: none;
    border: 2px solid theme('colors.grey');
  }
  svg {
    .primary {
      fill: theme('colors.white');
    }
    .secondary {
      fill: theme('colors.grey');
    }
  }
}

.big-button {
  @apply relative block py-3 my-4 text-sm text-center text-white no-underline border rounded-sm cursor-pointer  border-blue bg-blue;
  transition: all 0.3s ease-in-out;
  &:hover {
    @apply text-black bg-white border-black;
  }
  &--inverse {
    @apply relative block py-3 my-4 text-sm text-center text-white no-underline border border-white rounded-sm cursor-pointer;
    transition: all 0.3s ease-in-out;
    // relative block py-3 my-4 text-sm text-center text-white no-underline border border-white rounded-sm cursor-pointer hover:text-black hover:bg-white
    &:hover {
      @apply text-black bg-white border-black;
    }
  }
  &--pulse {
    // outline-width: 10px;
    outline-color: white;
    outline-style: solid;
    animation: outline-pulse 2s infinite;
    //animation-direction: alternate;
  }
}

@keyframes outline-pulse {
	0% {
    outline-width: 0;
    outline-color: rgba(255,255,255,1)
	}

	100% {
    outline-width: 8px;
    outline-color: rgba(255,255,255,0)
	}
}

@keyframes shadow-pulse {
	0% {
    box-shadow: inset 0 0 0 1px theme('colors.white'), 0 2px 10px 0 rgba(0,0,0,0.15);
	}

	100% {
    box-shadow: inset 0 0 0 4px theme('colors.white'), 0 2px 10px 0 rgba(0,0,0,0.25);
	}
}

@keyframes shadow-pulse-alt {
	0% {
    box-shadow: inset 0 0 0 1px theme('colors.coal'), 0 2px 10px 0 rgba(0,0,0,0.15);
	}

	100% {
    box-shadow: inset 0 0 0 4px theme('colors.coal'), 0 2px 10px 0 rgba(0,0,0,0.25);
	}
}
