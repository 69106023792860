.social-links {
    span {
        @apply cursor-pointer;
        &:hover {
            @apply text-blue;
        }
    }
    svg {
        @apply h-8 w-auto mx-2;
    }
    a:hover {
        svg path {
            @apply fill-blue;
        }
    }
}

.social-button {
    @apply p-3 border-highlight border inline-block rounded-full bg-white mx-2;
    &:hover {
        @apply border-blue;
    }
}