.footer {
  @apply text-sm text-global;
  a {
    @apply underline text-global;
  }
  nav a {
    @apply text-global no-underline;
    &:hover {
      @apply underline;
    }
  }
  input {
    @apply border-none border-transparent;
  }
}
