
.form-control {
  //padding-top: 25px;
  @apply mb-6;
  position: relative;

  .error-message {
    @apply text-sm;
    display: flex;
    align-items: center;
  }
}

input, textarea {
  @apply block leading-none px-4 py-3 w-full text-base border-solid border-coal border rounded-sm;
  position: relative;
  transition: border .3s ease-in-out;
  &.parsley-error {
    border-color: theme('colors.red');
    //+ label { color: theme('colors.red'); }
  }
  &:hover, &:focus {
    outline: none;
    border-color: theme('colors.blue');
    @apply bg-blue bg-opacity-5;
  }
  &:disabled {
    @apply bg-transparent pointer-events-none opacity-50;
  }
}
label {
  transition: all 0.3s;
  @apply block mb-0 text-base py-1;
}

.password-hide-show {
  @apply absolute top-0 right-0 z-10 h-full mr-3;
  .icon-hidden {
    @apply block;
  }
  .icon-visible {
    @apply hidden;
  }
  &.password-toggle-active {
    .icon-hidden {
      @apply hidden;
    }
    .icon-visible {
      @apply block;
    }
  }
}


@keyframes onAutoFillStart {
  from {/**/}
  to {/**/}
}

@keyframes onAutoFillCancel {
  from {/**/}
  to {/**/}
}

.form-control--simple {
  label {
    left: 0;
    position: absolute;
		top: 42px;
    left: 0;
    font-weight: normal;
  }
  .error-message {
    margin-bottom: 2em;
  }
  input {
    border-top: none;
    border-left: none;
    border-right: none;
    padding-left: 0;
    margin-bottom: 2em;
    border-radius: 0px;
    @apply bg-transparent;
    &.error {
      margin-bottom: 0;
    }
    &:focus + label {
      top: 5px;
      left: 0;
      font-weight: bold;
    }
    &.has-value + label {
      top: 5px;
      left: 0;
      font-weight: bold;
    }
    &:-webkit-autofill {
      // Expose a hook for JavaScript when auto fill is shown.
      // JavaScript can capture 'animationstart' events
      animation-name: onAutoFillStart;

      // Make the backgound color become yellow _really slowly_
      transition: background-color 50000s ease-in-out 0s;
    }

    &:not(:-webkit-autofill) {
      // Expose a hook for JS onAutoFillCancel
      // JavaScript can capture 'animationstart' events
      animation-name: onAutoFillCancel;
    }
  }
}
/**
General input placeholder
*/
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  @apply text-base;
  padding-top: 5px; // Added for Safari specifically
}
::-moz-placeholder { /* Firefox 19+ */
  @apply text-base;
}
:-ms-input-placeholder { /* IE 10+ */
  @apply text-base;
}
:-moz-placeholder { /* Firefox 18- */
  @apply text-base;
}
/**
Used to position an "optional" flag for form
fields. Applied to a child of the label element.
*/
label {
  .label__optional {
      float: right;
      font-weight: normal;
  }
}

.copy-tooltip {
  @apply absolute text-xs text-white border border-black rounded-sm bg-dystopia p-1 bottom-[-30px] left-[-3px];
  &:after {
    content: '';
    @apply absolute;
    left: 21px;
    top: -5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid theme('colors.dystopia');
  }
}