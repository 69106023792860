.pagination {
  @apply mt-12;
  display: flex;
  list-style: none;
  padding: 0;
  //margin-bottom: 3em;
  align-items: center;
  justify-content: center;
  li {
    display: flex;
    & > * {
      justify-content: center;
      items-align: center;
      box-shadow: 0 0 1px rgba(#aaa,0.5);
      border: 1px solid #EAEAEA;
      border-radius: 4px;
      margin-right: 3px;
      background-color: #FFFFFF;
      @apply px-2 py-1;
      @screen md {
        @apply px-3;
        border: 2px solid #EAEAEA;
        margin-right: 5px;
      }
    }
    //height: 45px;
    //width: 45px;
    &.active > * {
      @apply border-blue font-bold;
    }
    &.disabled {
      @apply hidden;
      @screen md {
        @apply flex;
      }
    }

    a {
      text-decoration: none;
      @apply w-full h-full;
      color: #262626;
      &:hover {
        @apply bg-blue text-white border-blue;
        a {
          @apply text-white ;
        }
      }
    }

  }
}
