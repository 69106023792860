.modal, .contribute-modal {
    transition: opacity 0.25s ease;
    opacity: 0;
    z-index: 999;
    @apply
        pointer-events-none;
}
body.modal-active {
    overflow: hidden;
    .mobile-nav {
        display: none;
    }
    .modal {
        opacity: 1;
        @apply
            pointer-events-auto;
    }
}
body.contribute-modal-active {
    overflow-x: hidden;
    overflow-y: visible !important;

    .contribute-modal {
        opacity: 1;
        @apply
            pointer-events-auto;
    }

}

body.modal-active, body.contribute-modal-active {
    .content {
        filter: blur(3px);
    }
}

.modal-overlay {
    @apply
        absolute w-full h-full bg-dystopia opacity-40;
}