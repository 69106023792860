:root {
	--color-primary: #e2979c;
	--color-primary-r: 226;
	--color-primary-g: 150;
	--color-primary-b: 156;
  }

.rich-text {
	h1 {
		@apply text-4xl;
	}
	h2 {
		@apply text-2xl mb-2;
	}
	h3 {
		@apply text-xl mt-8 mb-2;
	}
    //@apply first-letter:mt-2 first-letter:text-7xl first-letter:font-bold first-letter:font-serif first-letter:mr-3 first-letter:float-left;
	a {
		color: inherit;
		text-decoration: none;
		border-bottom: 1px solid var(--color-primary);
		padding: 0 2px;
		margin: 0 -2px;
		background: linear-gradient(to bottom, var(--color-primary) 0%, var(--color-primary) 100%),rgba(var(--color-primary-r), var(--color-primary-g), var(--color-primary-b), 0);
		background-position: 0 100%;
		background-repeat: repeat-x;
		background-size: .5px .5px;
		transition: background-size .2s;

		&:hover {
			background-size: 4px 50px;
			color: inherit;
		}
	}
	p {
		@apply mb-4;
		@screen md {
			@apply mb-8;
		}
	}

	ul {
		@apply list-disc pl-4 accent-democrat_blue mb-6;
		li {

		}
	}
}

ol, .rich-text .ol {
	list-style: none;
	counter-reset: section;
	margin-bottom: 1.5em;
	& > li {
	  padding-left: 2em;
      padding-right: 2em;
	  margin-bottom: .5em;
		&:before {
            @apply font-bold;
			counter-increment: section;
			content: counter(section) ". ";
			display: inline-block;
			//margin-top: 0.5em;
			width: 1.2em; /*same as padding-left set on li*/
		}
	}
}

// .rich-text .ol > li {
// 	&:before {
// 		margin-left: -1.2em;
// 	}
// }


// .rich-text .ul {
// 	list-style: none;
// 	counter-reset: section;
// 	margin-bottom: 1.5em;
// 	& > li {
// 	  padding-left: 2em;
//       padding-right: 2em;
// 	  margin-bottom: .5em;
// 	}
// }

.slideIn {
	opacity: 0;
	transform: translateY(40px);
	transition: all 0.5s ease;

	&--active {
	  opacity: 1;
	  transform: translateY(0);
	}
}

.faded  {
	opacity: 0;
	transition: opacity 3s ease-out;
	&--active {
		opacity: 1;
	}
}