.stepper {
    &__circle {
        @apply flex items-center justify-center w-8 h-8 mx-auto mb-2 text-white rounded-full bg-black cursor-pointer;

    }
    &__step {
        &--disabled {
            .stepper__circle {
                @apply pointer-events-none opacity-50;
            }

        }
        &--current {
            .stepper__circle {
                @apply pointer-events-none bg-blue text-white pointer-events-none;
            }
        }
    }
    &--step-1 {
        .stepper__step-1 {
            @apply opacity-50;
        }
    }
}