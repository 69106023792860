@tailwind base;
@tailwind components;
@tailwind utilities;

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

@import "~lite-youtube-embed/src/lite-yt-embed.css";

@import "global/colors";
@import "global/fonts";
@import "global/helpers";

@import "elements/body";
@import "elements/button";
@import "elements/checkbox";
@import "elements/inputs";
@import "elements/pagination";
@import "elements/radial";
@import "elements/selects";
@import "elements/tables";
@import "elements/hr";
@import "elements/svg";

@import "components/navbar";
@import "components/subnavbar";
@import "components/footer";
@import "components/alert";
@import "components/card";
@import "components/social-links";
@import "components/subjects";
@import "components/modal";
@import "components/big-search";
@import "components/faq";
@import "components/clouds";
@import "components/stepper";
@import "components/more-link";
@import "components/help";