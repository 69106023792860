.subnavbar {
    @apply bg-global text-white text-sm text-center;
    box-shadow: 0 1px 2px rgba(0,0,0,0.3);

    a {
        transition: color .3s ease-in-out;
        @apply
            text-grey
            py-4
            transition-colors
            no-underline;
        &:hover {
            @apply text-white;
            .counter {
                @apply bg-white text-dystopia;
            }
        }

    }
    &--active {
        a .counter {
            @apply bg-white text-dystopia;
        }
    }

    @screen md {
        @apply block sticky top-0 z-10 text-base;
    }
}

.counter {
    @apply bg-democrat_blue absolute text-white px-[7px] mt-2 py-[3px] rounded-full text-xs inline-block top-[-1px];
    @screen md {
        @apply inline-block py-[2px];
    }
}