hr {
    appearance: none;
    border: none;
    border-bottom: 1px solid theme('colors.grey');
    @apply my-2;
    @screen md {
        @apply my-4;
    }
    @screen md {
        @apply my-6;
    }

}