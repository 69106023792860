.big-search {
    border-bottom: 1px solid theme('colors.shadow');
    @apply mt-12 flex items-center;
    input {
        border: none;
        @apply bg-transparent appearance-none px-0 text-2xl font-light py-2 leading-none;
        &::placeholder {
            @apply text-2xl font-light;
        }
        @screen md {
            @apply text-2xl;
            &::placeholder {
                @apply text-2xl;
            }
        }
    }

}